/* You can add global styles to this file, and also import other style files */

$dark: #1b1e1f;
$light: #f1f1de;
$red: #a30b36;
$brown: #4e2200;

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.desktop-hero {
  img {
    visibility: hidden;
    display: none;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  font-family: 'Poppins', sans-serif;
}
h1,
h2,
h3 {
  font-weight: 700;
  margin-bottom: 2rem;
  line-height: 1.5rem;
}
p,
em {
  font-size: 0.8rem;
  line-height: 1.3rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
}
div.uneven.durability:first-of-type,
div.recipe-img,
.carousel {
  margin-top: -2rem;
}
.carousel {
  padding-top: 0;
 
}
button {
  border: 1px solid $red;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 2rem;
  font-size: 0.8rem;
  color: $red;
  font-weight: 700;
  background-color: transparent;
}
div.uneven:first-of-type {
  padding: 4rem 1rem;
  &.durability {
    .content {
      h1 {
        padding: 0;
      }
    }
  }
}
.privacy-table {
  td,
  a,
  strong,
  tr,
  th,
  ul li,
  ol li,
  u {
    font-size: 0.7rem;
  }
  a {
    text-decoration: underline;
    font-weight: 700;
    color: #a30b36;
  }
}
// MARGINS FOR BOTTOM MENU //
div.cheeses-list,
div.recipes-list,
div.durability:last-of-type,
form.contact-form,
section.mixed {
  padding-bottom: 6rem !important;
}
/* Custom select start */
// @Mitch zie https://moderncss.dev/custom-select-styles-with-pure-css/
:root {
  --select-border: #a30b36;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.filters {
  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0.25rem 0.5rem;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color: var(--select-border);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
  }
  .select {
    width: 100%;
    border: 1px solid var(--select-border);
    border-radius: 1em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
  }
  select,
  .select:after {
    grid-area: select;
    justify-self: end;
  }
  .select::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }
}
div.uneven.durability {
  
  h1 {
    padding: 1rem;
  }
}
div.uneven.durability.image-small, div.even.durability.image-small{
 
 
    .image-container{
      height: 15vh;
      img{
        object-fit: contain;
        width: 30%;
        text-align: left;
        height: 100%;
        padding: 0 1em;
      }
    }
  
}
form.contact-form {
  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0.25rem 0.5rem;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color: var(--select-border);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
  }
  .select {
    width: 100%;
    min-width: 10ch;
    max-width: 100%;
    border: 1px solid $dark;
    border-radius: 0.3rem;
    padding: 0.25rem 0.5rem;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    margin-bottom: 0;
  }
  select,
  .select:after {
    grid-area: select;
    justify-self: end;
  }
  .select::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: $dark;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }
  .custom-form-field:last-of-type {
    span.ng-star-inserted {
      grid-column-start: 1;
      grid-column-end: 3;
      font-size: 0.7rem;
      vertical-align: middle;
      line-height: 1rem;
      align-items: center;
      display: flex;
      margin-top: 1rem;
      a.material-symbols-outlined {
        color: $red;
      }
      p {
        margin-bottom: 0;
        margin-left: 0.5rem;
        font-size: 0.7rem;
      }
    }
  }
}
.privacy-table td,
.privacy-table strong,
.privacy-table tr,
.privacy-table th {
  color: $dark;
}
.privacy-table a {
  color: $red;
}
.privacy-table {
  width: 100%;
  padding: 1rem;
}
.mock-label {
  font-weight: 700;
  display: contents;
}
/* custom select end */
.filters {
  display: grid;
  padding: 4rem 1rem 0;
  margin-top: -3rem;
  column-gap: 1rem;
  grid-template-columns: 1fr;
  background-color: $dark;
}

/* Shared */
div.uneven {
  background-color: $light;
  color: $dark;
  width: 100vw;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  p {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}
div.even {
  background-color: $dark;
  color: $light;
  width: 100vw;
  img {
    width: 100%;
    height: auto;
  }
}
// Vormen voor images
img {
  &.round {
    border-radius: 50%;
  }
  &.rounded {
    border-radius: 25%;
  }
  &.square {
    border-radius: 5%;
  }
}
.btn-consent-container {
  background-color: $light;
  padding: 1rem;
  width: 100%;
  text-align: center;
}
div.header {
  text-align: center;
  position: relative;

  h1 {
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.04rem;
    margin: 2rem 0 0;
    width: 100%;
    padding: 0 0 1rem;
    font-size: 1.1rem;
  }
  img {
    width: inherit;
    max-width: 30vw;
    height: auto;
    margin: 0 auto;
  }
}
.related-recipes.recipes-list {
  background-color: $light;
  h2 {
    color: $dark;
  }
  .recipes-list-item {
    .info {
      color: $dark;
    }
  }
}
/* Mobile only */
body {
  background-color: $dark;
  color: $light;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}
div.hero-full {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  video {
    width: 100%;
    cursor: pointer;
    height: 100%;
  }
  .tagline {
    background-color: $dark;
    color: $light;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    min-height: 100px;
    width: 100%;
    img {
      height: 100%;
      width: auto;
      fill: $light;
      min-height: 100px;
      min-width: 100px;
    }
  }
}
div.header {
  .logo-container {
    border-bottom-left-radius: 80%;
    border-bottom-right-radius: 80%;
    width: 130vw;
    margin-left: -15vw !important;
    background: $dark;
    position: relative;
    padding: 1rem;
    z-index: 999;
  }

  video {
    width: 100%;
  }
  .desktop-hero {
    margin-top: -1rem;
    width: calc(100vw - 20rem);
    margin: -2rem auto 0;
  }
}
div.mixed {
  background-color: $dark;
  color: $light;
  .hero {
    video {
      width: 100%;
    }
  }
  section.intro {
    padding: 1rem;
    text-align: center;
    h1 {
      text-align: center;
      &.intro-title {
        max-width: 70vw;
        margin: 0 auto;
      }
    }
  }
  section.mixed {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
    a.home-block {
      display: block;
      width: calc((100vw - 3rem) / 2);
      height: calc((100vw - 3rem) / 2);
      &.cheese,
      &.moment {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
div.recipes-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: $dark;
  color: $light;
  min-height: 70vh;
  h2 {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 400;
    font-size: 0.7rem;
    span {
      font-size: 0.7rem;
    }
  }
  h3 {
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0.25rem 0;
    line-height: 1.2rem;
  }

  .recipes-list-item {
    display: grid;
    grid-template-columns: 30vw 1fr;
    column-gap: 0.5rem;
    margin-top: 1rem;
    text-decoration: none;
    img {
      height: 30vw;
      max-height: 100%;
      width: 30vw;
      max-width: 30vw;
      border-radius: 0.66rem;
      object-fit: cover;
    }
    .info {
      height: inherit;
      padding: 0 1rem;
      max-width: 60vw;
      align-self: center;
      color: $light;
      div.cooking-time {
        display: flex;
        align-items: center;
        span {
          font-size: 1rem;

          span.material-symbols-outlined {
            display: inline;
            width: 1.5rem;
            height: 1.5rem;
            color: white;
            fill: white;
          }
        }
        p {
          margin: 0 0 0 0.5rem;
          font-size: 0.8rem;
        }
      }
    }
  }
}
div.cheeses-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  row-gap: 0.5rem;
  padding: 1rem;
  background-color: $dark;
  .cheeses-list-item {
    display: grid;
    grid-template-columns: 40vw 1fr;
    column-gap: 1rem;
    margin-top: 1rem;
    color: $light;
    text-decoration: none;
    height: 40vw;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    .info {
      display: flex;
      /* vertical-align: middle; */
      color: $light;
      align-content: center;
      flex-wrap: wrap;
      h3 {
        font-size: 0.8rem;
        width: 100%;
        margin-bottom: 1rem;
      }
      span {
        font-size: 0.8rem;
        margin-right: 0.2rem;
        span.material-symbols-outlined {
          display: inline;
          width: 1.5rem;
          height: 1.5rem;
          color: white;
          fill: white;
        }
      }
    }
  }
}
div.moments-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 100px;
  margin-top: 1rem;
  column-gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  row-gap: 1rem;
  .moments-list-item img {
    height: 40vw;
    max-height: 100%;
    width: auto;
    max-width: 40vw;
  }
}
div.controls {
  position: fixed;
  top: calc(100vh - 6rem);
  left: 50vw;
  width: 50vw;
  height: 6rem;
  background-color: $dark;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  padding: 1rem;
  .cheese-icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    grid-row: 1 / 3;
  }
  .cheese-icon {
    width: 3.5rem;
    height: 3.5rem;
    content: url(../src/assets/icon-cheese.svg);
  }
  .facebook-icon,
  .instagram-icon,
  .menu-icon {
    width: 1.25rem;
    height: 1.25em;
  }
  .facebook-icon {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
    content: url(../src/assets/icon-facebook.svg);
    display: inline-block;
    align-self: flex-end;
  }
  .instagram-icon {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    content: url(../src/assets/icon-instagram.svg);
    display: inline-block;
    align-self: flex-start;
  }
  .menu-icon {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
    content: url(../src/assets/icon-menu.svg);
    font-size: 1rem;
    display: inline-block;
    align-self: flex-end;
  }
}
.privacy-title,
.privacy-content {
  color: $dark;
  padding: 2rem 1rem 1rem;
  background: $light;
  margin: 0;
  overflow-y: scroll;
}
.privacy-table{
  strong{
    font-weight: bold;
  }
}
.privacy-table {
  td,
  a,
  strong,
  tr,
  th,
  span {
    font-size: 0.7rem;
  }
  a {
    text-decoration: underline;
    font-weight: 700;
    color: #a30b36;
  }
}
.scroll-status{
  overflow: hidden;
}
.logo-container-menu{
  padding: 1rem;
  img{
    max-width: 25vw;
    height: auto;
  }
}

div.menu {
  position: fixed;
  bottom: 0vh;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  padding: 0;
  width: 100vw;
  z-index: 999;
  background-color: $dark;
  border-radius: 0 0 0 10rem;
  .close-icon {
    content: url(../src/assets/icon-close.svg);
    font-size: 1rem;
    position: fixed;
    right: 3.25rem;
    top: 2rem;
    transition: .5s;
  }
  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      text-decoration: none;

      &.page-link {
        color: $light;
        font-weight: 700;
        margin-top: 1rem;
        &.active {
          color: $red;
        }
        &.last {
          margin-bottom: 4rem;
        }
      }
      &.extra-link {
        color: $light;
        font-weight: 400;
        margin-top: 1rem;
        &:first-of-type {
          margin-top: 2rem;
        }
      }
    }
  }
  .languages {
    margin: 4rem 0;
    text-align: center;
    a {
      margin: 0 0.5rem;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        color: $red;
      }
    }
  }
}
div.info {
  color: $dark;
  padding: 1rem;
  &.cheese-detail {
    background-color: $light;
  }
  h1 {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.5rem;
    letter-spacing: 0.4pt;
    margin-bottom: 2rem;
  }
  p {
    font-size: 0.8rem;
    line-height: 1.3rem;
    letter-spacing: 0.25pt;
    font-weight: 400;
    margin-bottom: 2rem;
  }
}

div.basic-contact {
  padding: 2rem 1rem;
  margin-top: -1rem;
  strong {
    font-weight: 600;
    font-size: 0.8rem;
  }
  a.tel-number {
    text-decoration: none;
    color: $dark;
    font-size: 0.8rem;
    span {
      font-size: 1rem;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }
  .contact-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    .custom-form-field,
    .custom-select {
      display: grid;
      grid-template-columns: 1fr 3fr;
      margin-bottom: 0.5rem;
      label {
        font-weight: 700;
        font-size: 0.8rem;
        display: contents;
      }
      input,
      textarea {
        background-color: transparent;
        border: 1px solid $dark;
        padding: 0.3rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
      }
      select {
        background-color: transparent;
        border: 1px solid $dark;
        padding: 0.3rem;
        border-radius: 0.3rem;
        color: $dark;
        font-weight: 400;
        font-size: 0.8rem;
        &.contact-select,
        &.country-select {
          color: $dark;
          border: none;
        }
      }
      input[type='file'] {
        display: none;
      }
      .custom-file-upload {
        border: 1px solid $dark;
        display: inline-block;
        padding: 0.5rem 1rem;
        width: 100%;
        cursor: pointer;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        color: $dark;
        font-weight: 400;
      }
      .fake-label {
        font-weight: 700;
      }
      .field-error {
        color: red;
        grid-column-start: 2;
      }
      .has-errors {
        border-color: red;
      }
    }
    button {
      border: 1px solid $red;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-radius: 2rem;
      font-size: 0.8rem;
      color: $red;
      font-weight: 700;
      background-color: transparent;
      align-self: flex-end;
    }
  }
}
.recipe-subtitle {
  margin: 2rem 0 1rem 0;
}
div.recipe-container {
  padding: 1rem;
  background: $light;
  color: $dark;
  z-index: 999;
  position: relative;
  h1 {
    font-size: 1.2rem;
  }
  .cooking-info {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 2rem;
    div {
      display: flex;
      align-items: center;
      &:first-of-type {
        margin-right: 1rem;
      }
      p {
        margin-left: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
  .ingredients-list {
    margin-bottom: 1rem;
    ul {
      padding: 0 1rem;
    }
    li {
      font-style: italic;
      font-size: 0.8rem;
      margin: 0.25rem 0;
    }
  }
  .instructions {
    margin-bottom: 1rem;
    ol {
      padding: 0 1rem;
    }
    li {
      margin: 0.25rem 0;
      font-size: 0.8rem;
    }
  }
}
.recipe-video {
  margin: 0 auto;
  height: auto;
  iframe {
    aspect-ratio: 16 / 9;
    height: auto;
    width: 100%;
  }
}
.recipe-img {
  width: 100vw;
  height: 40vh;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
div.uneven {
  .image-container {
    height: 30vh;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    padding: 1rem;
    h1 {
      margin-bottom: 2rem;
    }
    .step-title {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 1rem;
      margin: 2rem 0;
      span {
        grid-row: 1 / 3;
        color: $red;
        font-size: 6rem;
        line-height: 5rem;
        font-weight: 700;
      }
      div {
        display: flex;
        h2 {
          margin-bottom: 0;
          margin-top: -0.085rem;
          padding: 0;
        }
        h3 {
          align-self: flex-end;
          font-size: 0.8rem;
          margin-bottom: 0.02rem;
        }
      }
    }
  }
}
div.even {
  .image-container {
    height: 30vh;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    padding: 1rem;
    .step-title {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr auto;
      column-gap: 1rem;
      margin: 2rem 0;
      span {
        grid-row: 1 / 3;
        color: $red;
        font-size: 6rem;
        line-height: 5rem;
        font-weight: 700;
      }
      div {
        display: flex;
        h2 {
          margin-bottom: 0;
          margin-top: -0.085rem;
        }
        h3 {
          margin-bottom: 0.02rem;
          align-self: flex-end;
          font-size: 0.8rem;
        }
      }
    }
  }
  p {
    font-size: 0.8rem;
  }
}
input:required::placeholder,
textarea:required::placeholder {
  color: $red;
}
input::placeholder {
  color: $dark;
}
.carousel-controls {
  text-align: center;
  padding: 1rem;
  background-color: $light;
  width: 100%;
  a {
    cursor: pointer;
    margin: 0 0.5rem;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 0.45rem;
    border: 1px solid $dark;
    background-color: $light;
    display: inline-block;

    &.active {
      background-color: $dark;
    }
  }
}
@media screen and (min-width: 481px) {
  .filters {
    grid-template-columns: 1fr 1fr;
  }
  div.controls {
    width: 30vw;

    left: 70vw;
  }
}
/* TABLETS AND SMALL LAPTOPS */
@media screen and (min-width: 769px) {
  div.cheeses-list {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    .cheeses-list-item {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 1rem;
      height: 25vw;
      img {
        width: 25vw;
        height: 25vw;
      }
    }
  }
  div.uneven.durability.image-small, div.even.durability.image-small{
   
   
      .image-container{
        height: 15vh;
        img{
          object-fit: contain;
          width: 20%;
          text-align: left;
          height: 100%;
          padding: 0 1em;
        }
      }
    
  }
  div.controls {
    width: 30vw;

    left: 70vw;
  }
  .filters {
    padding: 4rem 4rem 0;
  }
  div.uneven.durability:first-of-type,
  div.recipe-img,
  .carousel {
    margin-top: -3rem;
  }
  div.recipe-img {
    width: calc(100vw - 30rem);
    height: 50vh;
    padding-bottom: 2rem;
    margin: -2rem auto 0;
  }
  div.mixed {
    padding: 6rem 0;
    min-height: 70vh;
  }
  div.basic-contact {
    margin-top: -3rem;
    padding: 6rem;
    min-height: 95vh;
  }
  div.header {
    div.logo-container {
      margin-left: calc(-15vw - 1rem) !important;
      padding: 1rem 0;
    }
  }

  div.menu {
    padding: 2rem 0;
    z-index: 999;
  }

  div.header.rounded,
  div.header.normal {
    video {
      width: 100%;
    }
  }
  div.header {
    img {
      max-width: 20vw;
    }
  }
  section.mixed {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 3rem 10rem;
    a.home-block {
      width: calc((100vw - 22rem) / 3);
      height: calc((100vw - 22rem) / 3);
    }
    a.home-block:first-of-type {
      grid-row-start: 1;
      grid-column-start: 1;
    }
    a.home-block:nth-of-type(2) {
      grid-row-start: 1;
      grid-column-start: 2;
    }
    a.home-block:nth-of-type(3) {
      grid-row-start: 2;
      grid-column-start: 1;
    }
    a.home-block:nth-of-type(4) {
      grid-row-start: 1;
      grid-column-start: 3;
    }
    a.home-block:nth-of-type(5) {
      grid-row-start: 2;
      grid-column-start: 2;
    }
    a.home-block:nth-of-type(6) {
      grid-row-start: 2;
      grid-column-start: 3;
    }
  }
  div.mixed {
    section.intro {
      padding: 0 10rem;
      h1 {
        &.intro-title {
          max-width: 40vw;
          margin: 0 auto;
        }
      }
    }
    section.mixed {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 3rem 10rem;
      a.home-block {
        width: calc((100vw - 22rem) / 3);
        height: calc((100vw - 22rem) / 3);
      }
      a.home-block:first-of-type {
        grid-row-start: 1;
        grid-column-start: 1;
      }
      a.home-block:nth-of-type(2) {
        grid-row-start: 1;
        grid-column-start: 2;
      }
      a.home-block:nth-of-type(3) {
        grid-row-start: 2;
        grid-column-start: 1;
      }
      a.home-block:nth-of-type(4) {
        grid-row-start: 1;
        grid-column-start: 3;
      }
      a.home-block:nth-of-type(5) {
        grid-row-start: 2;
        grid-column-start: 2;
      }
      a.home-block:nth-of-type(6) {
        grid-row-start: 2;
        grid-column-start: 3;
      }
    }
  }
}
/* DESKTOP */
@media screen and (min-width: 1025px) {
  body {
    background: $dark;
  }
.privacy-title,
.privacy-content{
overflow-y: scroll;
padding: 2rem 10rem 1rem;
}
  .privacy-table a {
    color: $red;
  }
  .privacy-table {
    width: 100%;

  }
  div.controls {
    height: 100vh;
    width: 5.25rem;
    top: 0;
    left: calc(100vw - 5.25rem);
    grid-template-columns: 1fr;
    grid-template-rows: 14fr 1fr 1fr 1fr;
    column-gap: 0;
    z-index: 999;
    padding: 2rem 1rem;
    .cheese-icon-container {
      grid-row: 2 / 2;
      grid-column-start: 1;
      .cheese-icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .instagram-icon {
      grid-row: 4 / 4;
      grid-column-start: 1;
      align-self: center;
    }
    .facebook-icon {
      grid-row: 3 / 3;
      grid-column-start: 1;
      align-self: center;
    }
    .menu-icon {
      grid-row: 1 / 1;
      grid-column-start: 1;
      align-self: flex-start;
    }
  }

  div.header.rounded,
  div.header.normal {
    video {
      width: calc(100vw - 20rem);
      margin: -3rem auto;
    }
  }
  .carousel {
    /*width: calc(100vw - 20rem);
    margin: -3rem auto;*/
    .sliders {
      height: 45vw !important;
    }
  }
  div.header img {
    max-width: 15vw;
  }
  div.mixed {
    section.intro {
      padding: 0 10rem;
      text-align: center;
      h1 {
        max-width: 30vw;
        margin: 1rem auto;
      }
    }
    section.mixed {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 3rem 10rem;
      a.home-block {
        width: calc((100vw - 22rem) / 3);
        height: calc((100vw - 22rem) / 3);
      }
    }
  }
}
/* DESKTOP */
@media screen and (min-width: 1201px) {
  body {
    background: $dark;
  }
  .desktop-hero {
    img {
      object-fit: cover;
      visibility: visible;
      display: block;
      width: 100% !important;
      max-width: 70% !important;
      margin-top: -2rem !important;
    }
  }
  div.header {
    img {
      max-width: 12vw;
      padding-bottom: 1rem;
    }
  }
  .logo-container {
    padding: 1rem 0;
    background-color: $dark;
    border-bottom-left-radius: 80%;
    border-bottom-right-radius: 80%;
    position: relative;
    z-index: 999;
    width: 130%;
    margin-left: -15%;
    img.logo {
      width: 15vw;
      margin: 0 auto;
    }
  }
  div.recipe-container {
    padding: 1rem 25rem;
  }
  div.recipe-img {
    width: calc(100vw - 50rem);
  }
  div.basic-contact {
    padding: 6rem 25rem !important;
    margin-top: -5rem;
    form.contact-form {
      select {
        max-width: 100%;
      }
    }
  }
  div.cheeses-list {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem 25rem;
    .cheeses-list-item {
      display: flex;
      flex-direction: column;
      .info {
        text-align: center;
        h3 {
          margin-bottom: 0;
        }
        span {
          width: 100%;
          text-align: center;
        }
      }
      img {
        object-fit: cover;
        margin: 0 auto;
        max-width: 15vw;
        max-height: 15vw;
      }
    }
  }
  .filters {
    margin-top: 0rem;
    padding: 0 25rem 1rem;
  }
  .carousel {
    width: calc(100vw - 50rem);
    overflow: hidden;
    margin: -2rem auto;
    padding-bottom: 2rem;
    .carousel-controls {
      background: $dark;
      a {
        border-color: $light;
        background-color: transparent;
        &.active {
          background-color: $light;
        }
      }
    }
    .sliders {
      width: 100%;
      height: 35vw !important;
      .slider {
        overflow: hidden;
      }
      img {
        object-fit: contain;
      }
    }
  }
  div.info.cheese-detail {
    margin-top: 5rem;
    padding: 4rem 25rem;
  }
  div.moments-list {
    padding: 1rem 10rem;
    grid-template-columns: 1fr 1fr 1fr;
    a.moments-list-item {
      img {
        width: calc((100vw - 22rem) / 3);
        height: calc((100vw - 22rem) / 3);
      }
    }
  }
  div.recipes-list {
    padding: 1rem 25rem;
    .recipes-list-item {
      grid-template-columns: 15vw 1fr;
      img {
        width: 15vw;
        height: 15vw;
      }
    }
  }
  div.uneven {
    .content {
      padding: 1rem 0;
      .step-title {
        grid-template-columns: 1fr 5fr;
      }
    }
  }
  div.even {
    .content {
      padding: 1rem 0;
      .step-title {
        grid-template-columns: 1fr 5fr;
      }
    }
  }
  div.uneven.durability:first-of-type {
    margin-top: -5rem;
  }
  div.uneven:first-of-type,
  div.durability {
    padding: 6rem 25rem;
  }
  .privacy-title,
  .privacy-content {
    color: $dark;
    padding: 4rem 25rem 1rem;
  }
  .privacy-table {
    td,
    a,
    strong,
    tr,
    th,
    ul li,
    ol li {
      font-size: 0.7rem;
    }
    a {
      text-decoration: underline;
      font-weight: 700;
      color: #a30b36;
    }
  }
}

// PRINT CSS //
@media print {
  .header,
  .footer,
  .controls,
  .related-recipes,
  button {
    display: none !important;
  }
  .ingredients-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}
